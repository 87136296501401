import React from 'react';
import {makeStyles} from "@mui/styles";


const onePx = 0.0625;
export default makeStyles((theme) => ({
    kurMain: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
        maxWidth: 600,
        margin: "0 auto",
    },
    kurFilterBar: {
        padding: 8,
        width: "calc(100% - 16px)",
        display: "flex",
        flexWrap: "wrap",
        //position: "fixed",
        background: "#fff",
        //top: 0,
        //left: 0,
        "& .MuiFormControl-root": {
            margin: "4px 8px!important"
        },

    },
    menu: {
        /*
        background: "#f1f1f1!important",
        "& .MuiMenu-paper": {
            background: "#f1f1f1!important",
        }
         */
    },
    scrollBoxRe: {
        overflow: "auto",
        width: "100%",
        flex: 1,
        height: "calc(100% - 155px)",
        //height: "100%",
        padding: "12px 12px 0 12px",
        "& .MuiCard-root": {
            overflow: "unset",
        },
        "& .infinite-scroll-component": {
            "display": "flex",
            "flexWrap": "wrap",
        },
    },
    reItemWrapper: {
        width: "100%",
        minWidth: 200,
        padding: "10px",
        borderRadius: 8,
        border: "1px solid #eaeff3",
        boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        marginBottom: 20,
    },

    reItemHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        "& p": {
            textAlign: "center",
            margin: 0,
            width: "100%",
        }
    },
    cover: {
        display: "flex",
        justifyContent: "center",
        "& img": {
            width: "50%",
        }
    },
    noPhoto: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 200,
    },
    noPhotoIcon: {
        "fontSize": "9rem!important",
        "color": "#f3f7f9",
        //"background": "#e8ecef",
    },
    kurFilterBarCheckSteps: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 4,
        width: "100%",
        alignItems: "center",
        "& .MuiButtonBase-root": {
            padding: 5,
        },
        "& .MuiFormControlLabel-root": {
            marginRight: 15,
        }
    }


}));